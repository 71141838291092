<script>
import TablePage from '../../../../../../../components/table_page';
import ConditionDetail from '../condition_detail.vue';
import FrozenAdjust from '../frozen_adjust.vue';
import ManualEntryForm from '../manual_entry_form.vue';
import TotalAdjustTwo from '../total_adjust_two.vue';

export default {
  extends: TablePage,
  components: {
    ConditionDetail,
    FrozenAdjust,
    ManualEntryForm,
    TotalAdjustTwo,
  },
  props: {
    poolCode: String,
    conditionInfo: Object,
    operationType: String,
  },
  data() {
    return {
      requestUrl: '',
      params: {},
      formConfig: {},
      selection: [],
      isAdjust: false,
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  watch: {
    operationType(n) {
      this.params.operationType = n;
      this.getList();
    },
  },
  created() {
    this.params.poolCode = this.poolCode;
    this.getConfigList('dms_discount_goods_condition');
  },
  methods: {
    clickVisible({ val, row }) {
      if (row.freezeAmount === 0 && val.buttonCode === 'dms_thaw') {
        return false;
      }
      if (row.usableAmount === 0 && val.buttonCode === 'dms_frozen') {
        return false;
      }
      return true;
    },
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'fileList') {
        rowData.type = 'html';
        rowData.formatter = ({ cellValue }) => {
          if (cellValue && cellValue.length > 0) {
            const list = [];
            cellValue.forEach((val) => {
              list.push(`<a href=${val.url}>${val.fileName}</a>`);
            });
            return list.join(',');
          }
          return '';
        };
      }
      return rowData;
    },
    beforeGetList() {
      if (this.isAdjust) {
        this.isAdjust = false;
        this.$emit('adjusTable');
      }
      return true;
    },
    modalClick({ row, val }) {
      if (val.code === 'getDetail') {
        this.modalConfig.title = '详情';
        this.formName = 'ConditionDetail';
        this.modalFormData = row;
        this.openFull();
      } else if (val.code === 'dms_frozen') {
        this.isAdjust = true;
        this.modalConfig.title = '冻结';
        this.formName = 'FrozenAdjust';
        this.formConfig.btnCode = val.code;
        this.formConfig.id = row.id;
        this.openDrawer();
      } else if (val.code === 'dms_thaw') {
        this.isAdjust = true;
        this.modalConfig.title = '解冻';
        this.formName = 'FrozenAdjust';
        this.formConfig.btnCode = val.code;
        this.formConfig.id = row.id;
        this.openDrawer();
      } else if (val.code === 'manual_entry') {
        this.modalConfig.title = '手动上账';
        this.formName = 'ManualEntryForm';
        this.formConfig.selection = [];
        this.formConfig.isDetail = true;
        this.openDrawer();
      } else if (val.code === 'total_adjust') {
        this.modalConfig.title = '调整';
        this.formName = 'TotalAdjustTwo';
        this.formConfig = row;
        this.formConfig.isDetail = true;
        this.openDrawer();
      }

      return true;
    },
  },
};
</script>
