<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [],
      dictCodeList: {},
    };
  },
  components: {
  },
  async mounted() {
    const rule = await this.getBeforFormRule('dms_discount_frozen_adjust2');
    const dictCodeList = [];
    rule.map((v) => {
      const rowData = v;
      let isDisabled = false;
      if (rowData.field === 'amount') {
        rowData.title = this.formConfig.btnCode === 'dms_frozen' ? '冻结金额' : '解冻金额';
      }
      switch (rowData.field) {
        case 'poolDetailCode': isDisabled = true; break;
        case 'usableAmount': isDisabled = true; break;
        case 'freezeAmount': isDisabled = true; break;
        default: isDisabled = false;
          break;
      }
      rowData.props = {
        ...rowData.prop,
        disabled: isDisabled,
      };
      return rowData;
    });
    this.rule = rule;
    this.reload(rule);
    if (this.formConfig.id) {
      this.getData();
    }
  },
  methods: {
    afterReload() {

    },
    getData() {
      const path = '/fee/feePoolDetailController/queryById';
      request
        .get(path, { id: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
              fileList: [],
            });
          }
        });
    },

    // 提交
    submit() {
      if (!this.getFormData()) return false;
      const params = {
        ...this.getFormData(),
      };
      let path = '/fee/feePoolDetailController/freeze';
      if (this.formConfig.btnCode === 'dms_thaw') {
        path = '/fee/feePoolDetailController/unfreeze';
      }
      request
        .post(path, params)
        .then((res) => {
          if (res.success) {
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
  },
};
</script>
